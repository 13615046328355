<template>
  <div class="curriculum">
    <div class="div_cc div_cc3 ph-hidden">
      <a @click="toCourseTypePage(courseType[2].id)">
        <span class="s1">分类课程</span>
      </a>
      <ul>
        <li>
          <img src="../images/course/c3_1.png" alt="" />
          <span>语文</span>
        </li>
        <li>
          <img src="../images/course/c3_2.png" alt="" />
          <span>外语</span>
        </li>
        <li>
          <img src="../images/course/c3_3.png" alt="" />
          <span>历史</span>
        </li>
        <li>
          <img src="../images/course/c3_4.png" alt="" />
          <span>地理</span>
        </li>
        <li>
          <img src="../images/course/c3_5.png" alt="" />
          <span>政治</span>
        </li>
        <li>
          <img src="../images/course/c3_6.png" alt="" />
          <span>体育</span>
        </li>
        <li>
          <img src="../images/course/c3_7.png" alt="" />
          <span>技术</span>
        </li>
        <li>
          <img src="../images/course/c3_8.png" alt="" />
          <span>商学与经济学</span>
        </li>
      </ul>
    </div>
    <div class="div_cc div_cc1">
      <a @click="toCourseTypePage(courseType[0].id)">
        <span class="s1">分层课程</span>
      </a>
      <ul>
        <li>
          <img src="../images/course/c1_1.png" alt="" />
          <span>数学</span>
        </li>
        <li>
          <img src="../images/course/c1_2.png" alt="" />
          <span>物理</span>
        </li>
        <li>
          <img src="../images/course/c1_3.png" alt="" />
          <span>化学</span>
        </li>
        <li>
          <img src="../images/course/c1_4.png" alt="" />
          <span>生物</span>
        </li>
      </ul>
    </div>
    <div class="div_cc div_cc3 ph-show">
      <a @click="toCourseTypePage(courseType[2].id)">
        <span class="s1">分类课程</span>
      </a>
      <ul>
        <li>
          <img src="../images/course/c3_1.png" alt="" />
          <span>语文</span>
        </li>
        <li>
          <img src="../images/course/c3_2.png" alt="" />
          <span>外语</span>
        </li>
        <li>
          <img src="../images/course/c3_3.png" alt="" />
          <span>历史</span>
        </li>
        <li>
          <img src="../images/course/c3_4.png" alt="" />
          <span>地理</span>
        </li>
        <li>
          <img src="../images/course/c3_5.png" alt="" />
          <span>政治</span>
        </li>
        <li>
          <img src="../images/course/c3_6.png" alt="" />
          <span>体育</span>
        </li>
        <li>
          <img src="../images/course/c3_7.png" alt="" />
          <span>技术</span>
        </li>
        <li>
          <img src="../images/course/c3_8.png" alt="" />
          <span>商学与经济学</span>
        </li>
      </ul>
    </div>
    <div class="div_cc div_cc2">
      <a @click="toCourseTypePage(courseType[1].id)">
        <span class="s1">综合课程</span>
      </a>
      <ul>
        <li>
          <img src="../images/course/c2_1.png" alt="" />
          <span>艺术</span>
        </li>
        <li>
          <img src="../images/course/c2_2.png" alt="" />
          <span>综合实践</span>
        </li>
        <li>
          <img src="../images/course/c2_3.png" alt="" />
          <span>游学</span>
        </li>
        <li>
          <img src="../images/course/c2_4.png" alt="" />
          <span>高端项目研究</span>
        </li>
        <li>
          <img src="../images/course/c2_5.png" alt="" />
          <span>人文与社会</span>
        </li>
        <li>
          <img src="../images/course/c2_6.png" alt="" />
          <span>劳动教育</span>
        </li>
      </ul>
    </div>

    <div class="div_cc div_cc4">
      <a @click="toCourseTypePage(courseType[3].id)">
        <span class="s1">特需课程</span>
      </a>
      <ul>
        <li>
          <img src="../images/course/c4_1.png" alt="" />
          <span>书院课程</span>
        </li>
        <li>
          <img src="../images/course/c4_2.png" alt="" />
          <span>特种体育</span>
        </li>
        <li>
          <img src="../images/course/c4_3.png" alt="" />
          <span>援助课程</span>
        </li>
        <li>
          <img src="../images/course/c2_2.png" alt="" />
          <span>校企合作</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    courseType: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async toCourseTypePage(typeId) {
      this.$router.push({
        path: '/portalYZSYCourseNormalDetail',
        query: {
          typeId,
        },
      })
    },
  },
}
</script>

<style>
</style>
